
const Logo = () => {
    // @ts-ignore
    return (
        <svg width="120px" height="120px" viewBox="0 0 300 102" className="css-1j8o68f">
            <defs id="SvgjsDefs2428"></defs>
            <g
                id="SvgjsG2429"
                // featurekey="rootContainer"
                transform="matrix(1,0,0,1,0,0)"
                fill="#ffffff"
            >
                <rect y="0" height="1" width="1" opacity="0"></rect>
                <rect y="97" width="300" height="5"></rect>
            </g>
            <g
                id="SvgjsG2430"
                // featurekey="QMusi1-0"
                transform="matrix(0.8888888888888888,0,0,0.8888888888888888,-4.444444444444445,7.696000840928821)"
                fill="#ffffff">
                <polygon xmlns="http://www.w3.org/2000/svg" points="93.13 79.28 91.877 79.28 78.061 79.28 50.926 28.535 55.364 20.235 53.927 19.467 50.002 26.807 46.076 19.467 44.64 20.235 49.078 28.535 21.939 79.28 12.518 79.28 16.383 79.28 5 79.28 5 80.533 21.269 80.533 42.203 80.533 42.204 80.533 50.002 65.855 57.133 79.28 57.132 79.28 57.797 80.533 57.799 80.533 62.932 80.533 76.707 80.533 78.731 80.533 95 80.533 95 79.28 93.13 79.28">
                </polygon>
            </g>
            <g
                id="SvgjsG2431"
                // featurekey="UyNsn2-0"
                transform="matrix(5.002501307903036,0,0,5.002501307903036,96.59830414377458,-15.516762579555952)"
                fill="#ffffff">
                <path d="M5.56 9.44 c0.69334 0 1.34 0.09668 1.94 0.29002 s1.1167 0.50334 1.55 0.93 s0.77334 0.98 1.02 1.66 s0.37 1.5 0.37 2.46 s-0.12334 1.78 -0.37 2.46 s-0.58666 1.2333 -1.02 1.66 s-0.95 0.73666 -1.55 0.93 s-1.2467 0.29 -1.94 0.29 c-0.70666 0 -1.3567 -0.09666 -1.95 -0.29 s-1.1067 -0.50334 -1.54 -0.93 s-0.77334 -0.98 -1.02 -1.66 s-0.37 -1.5 -0.37 -2.46 c0 -0.94666 0.12334 -1.7567 0.37 -2.43 s0.58666 -1.2267 1.02 -1.66 s0.94668 -0.75 1.54 -0.95 s1.2433 -0.3 1.95 -0.3 z M5.56 17.5 c0.6 0 1.07 -0.2 1.41 -0.6 s0.51 -1.1067 0.51 -2.12 s-0.17 -1.7233 -0.51 -2.13 s-0.81 -0.61 -1.41 -0.61 c-0.58666 0 -1.0567 0.21 -1.41 0.63 s-0.53 1.1233 -0.53 2.11 c0 1.0133 0.17334 1.72 0.52 2.12 s0.82 0.6 1.42 0.6 z M12.86 12.059999999999999 l-1.24 0 l0 -2.52 l1.24 0 l0 -2.84 l3 0 l0 2.84 l2.48 0 l-1.4 2.52 l-1.18 0 l0.1 0.64 l0 3.14 c0 0.34666 0.02334 0.62666 0.07 0.84 s0.12 0.38 0.22 0.5 s0.22666 0.20334 0.38 0.25 s0.33 0.07 0.53 0.07 c0.14666 0 0.32666 -0.01666 0.54 -0.05 s0.44 -0.07 0.68 -0.11 l0.16 1.34 l0.12 1.2 c-0.38666 0.08 -0.74 0.14 -1.06 0.18 s-0.64666 0.06 -0.98 0.06 c-1.24 0 -2.16 -0.28666 -2.76 -0.86 s-0.9 -1.52 -0.9 -2.84 l0 -4.36 z M24.419999999999998 9.44 c0.69334 0 1.34 0.09668 1.94 0.29002 s1.1167 0.50334 1.55 0.93 s0.77334 0.98 1.02 1.66 s0.37 1.5 0.37 2.46 s-0.12334 1.78 -0.37 2.46 s-0.58666 1.2333 -1.02 1.66 s-0.95 0.73666 -1.55 0.93 s-1.2467 0.29 -1.94 0.29 c-0.70666 0 -1.3567 -0.09666 -1.95 -0.29 s-1.1067 -0.50334 -1.54 -0.93 s-0.77334 -0.98 -1.02 -1.66 s-0.37 -1.5 -0.37 -2.46 c0 -0.94666 0.12334 -1.7567 0.37 -2.43 s0.58666 -1.2267 1.02 -1.66 s0.94668 -0.75 1.54 -0.95 s1.2433 -0.3 1.95 -0.3 z M24.419999999999998 17.5 c0.6 0 1.07 -0.2 1.41 -0.6 s0.51 -1.1067 0.51 -2.12 s-0.17 -1.7233 -0.51 -2.13 s-0.81 -0.61 -1.41 -0.61 c-0.58666 0 -1.0567 0.21 -1.41 0.63 s-0.53 1.1233 -0.53 2.11 c0 1.0133 0.17334 1.72 0.52 2.12 s0.82 0.6 1.42 0.6 z M29.78 9.54 l3.48 0 l1.96 6.62 l0.04 0.24 l0.04 -0.24 l1.92 -6.62 l3.44 0 l-3.92 10.48 l-3.12 0 z">

                </path>
            </g>
        </svg>
    );
};

export default Logo;